import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 최근 카카오페이 통신/로밍 메뉴 런칭, 가입자 확대를 위한 이통3사 의존형 출혈경쟁 보다 고객편의에 집중 투자",
  "| 통신플랫폼 ‘핀다이렉트’ 출시 이후 고객센터 고도화 지속… 카카오톡 기반 고객만족 서비스 체험 후 이용자 호평 이어져",
  "| 평균 1분 이내 고객 응대… 즉각적이면서도 친절한 통신플랫폼 고객센터로 자리매김 할 것",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/36/36_img1.png" alt="Figure2" />
      </div>
      <div className="desc">&lt;(좌)카카오페이 통신/로밍 메뉴 (우)핀다이렉트 AI개통 간편화 화면&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)가 15일 카카오페이 통신/로밍 서비스를 오픈 후 고객센터를 더욱 고도화 하는데 집중하고 있다. 최근
      알뜰폰 0원 요금제 도입 후 일부 알뜰폰 사업자의 고객센터 마비, 개통 지연 등 부작용이 따르고 있는데 반하는 행보다. 상담
      인입점도 카카오톡채널, 핀다이렉트 앱을 통하면 쉽게 인지하고 컨택 할 수 있다.
      <br />
      <br />
      스테이지파이브 고객센터는 전담 내부 직원을 대상으로 분야별 전문상담교육을 통해 통신관련 고객 어려움을 해결하는데 집중한다.
      🔺개통상담 🔺eSIM상담 🔺로밍상담 등으로 유형을 세분화 하고 건별로 전문상담사가 배정되어 응대하는 형식이다.
      <br />
      <br />
      개통상담의 경우 상담원 응대 뿐 아니라 AI엔진을 도입하여 고객에게 불편함을 야기하는 개통절차 자체를 간소화 하였다. 해당
      서비스 도입 이후 상담 인입율이 획기적으로 개선되었으며, 고객들은 상담 지연 등의 병목현상 없이 보다 쾌적한 환경에서 상담을
      받을 수 있게 되었다. 평균적으로 응대에 걸리는 시간은 1분 미만이다. 한 번 인입한 고객이 이탈없이 끝까지 한 명의 상담원에게
      응대받는 지수를 뜻하는 ‘원스톱 응대율’은 98.5%로 업계 최고 수준이다.
      <br />
      <br />
      eSIM상담의 경우 국내 런칭된 지 9개월이 지났지만 아직 고객에게 익숙하지 않은 UX를 돕고자 단말기 정보 확인, 본인인증 등의
      번거로운 절차를 간소화 하였다. 뿐만 아니라 숙련된 전문 상담사와의 실시간 톡을 통해 eSIM 다운로드부터 설치까지 매끄럽게
      진행할 수 있도록 하였다.
      <br />
      <br />
      로밍상담의 경우 상품 특성을 고려하여 24시간 전담 고객센터를 운영 중이다. 해외 143개국에서 사용 가능한 핀다이렉트 로밍을 이용
      중인 고객이라면 장소나 시간에 구애받지 않고 전문상담사와 다이렉트 상담이 가능하다.
      <br />
      <br />
      스테이지파이브 고객만족센터 정구현 팀장은 “기존에도 자사의 우수한 고객센터 서비스에 대한 호평이 많았는데, 카카오페이
      통신/로밍 메뉴로 인입된 고객들의 경우 상담까지 카톡으로 이어 해결할 수 있는 경험에 특히 만족해하고 있다” 고 전했다.
      <br />
      <br />
      스테이지파이브 구독사업부문 변철훈 부문장은 “스테이지파이브는 전체 예산 중 대부분을 고객의 만족스러운 경험을 위한 투자
      용처로 사용 중”이라며, “정책금에 의존한 0원 요금제 출혈경쟁에서 벗어나, 고객을 위한 투자에 더욱 집중하여 합리적이고 편리한
      통신서비스를 제공할 수 있도록 최선을 다할 것”이라고 밝혔다.
      <br />
      <br />
      스테이지파이브는 2015년 출범 이후 ‘고객우선’ 기조를 지속적으로 이어나갔다. 특히 설비투자 관점에서 통신업계 최초로 카카오페이
      인증서를 도입해 고객 편의성 증진에 기여한 바 있다. 또한, 앱 내 요금제 상세스펙 확인 및 잔여량 조회 기능 도입은 물론이고 상기
      서술한 AI기반 개통 자동화 구축, eSIM가입 및 개통 자동화 구축, 맞춤형 혜택 안내 자동화 구축, 로밍 실시간 내역까지 확인 가능한
      위젯 제공, 빌링 내제화 진행 등을 통해 기술기반 통신플랫폼 사업자로 거듭나고자 집중하고 있다.
      <br />
      <br />
      한편, 스테이지파이브는 금융사인 카카오페이와 함께 통신/로밍 메뉴를 신설한 바 있다. 해당 메뉴는 카카오톡 내 카카오페이 홈
      메인에 신설되었다. 따라서 카카오톡 사용자라면 앱이탈 없이 ‘통신/로밍’ 메뉴를 통해 요금제, 데이터로밍, 리뉴드 디바이스 등을
      둘러보고 구매까지 가능하게 되었다.
      <br />
    </p>
  </div>
)

const press36 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 5월 19일 조간 즉시배포 가능"
      pageInfo="(사진자료 메일 첨부)"
      title="스테이지파이브, 고객 서비스 품질 강화로 브랜드 신뢰 증진"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press36
